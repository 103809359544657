import { Link } from "gatsby";

import { Meta, Layout, Section } from "~/ui";
import { Routes } from "~/models";

import volunteerPainting from "~/assets/images/volunteer-painting.jpg";

export default function PrivacyPolicy(): JSX.Element {
  return (
    <Layout>
      <Section
        bgColor="creamGradient"
        baseBgColorTop="primary"
        roundedCorners={{ "top-left": true }}
      >
        <div className="row">
          <div className="col-12 col-lg-8 mb-4">
            <h1 className="d-flex flex-column text-primary">
              <span>Your</span>
              <span className="display-1">Privacy</span>
            </h1>
            <p className="lead">
              We know that your privacy is important to you, so that means your
              privacy is important to us as well.
              <br />
              You can read the full details of our privacy policy below. As a
              quick summary:
            </p>
            <ul className="mb-0">
              <li className="mb-2">
                We only ask for personal information that we need.
              </li>
              <li className="mb-2">
                We will tell you what information we have recorded about you.
              </li>
              <li className="mb-2">
                If you want any personal information changed or deleted, just
                ask.
              </li>
            </ul>
          </div>
          <div className="col-12 col-lg-4 text-center mb-4">
            <img
              src={volunteerPainting}
              alt="Volunteer painting a fence"
              className="img-fluid mt-lg-n5"
              width={364}
              height={489}
            />
          </div>
        </div>
      </Section>

      <Section
        bgColor="offWhite"
        baseBgColorTop="creamGradientEnd"
        roundedCorners={{ "top-right": true }}
      >
        <div className="row mb-4">
          <div className="col-12 col-lg-8 offset-lg-1 mb-4">
            <article className="mb-4">
              <h2 className="h3 font-weight-normal text-success mb-2">
                Who we are
              </h2>
              <p>This is the Website of Volunteering WA.</p>
            </article>
            <article className="mb-5">
              <h2 className="h3 font-weight-normal text-success mb-2">
                What we know about you
              </h2>
              <p>
                When you visit our Web site, our Web server automatically
                records some general information about your visit, but does not
                recognise or record any individual information about you. The
                information we record and use for statistical purposes is:
              </p>
              <ul>
                <li>Your server address</li>
                <li>The date and time</li>
                <li>The pages accessed and documents downloaded</li>
                <li>The previous site visited</li>
                <li>The type of browser used</li>
                <li>
                  We can only identify you personally if you give us this
                  information voluntarily.
                </li>
              </ul>
            </article>
            <article className="mb-5">
              <h2 className="h3 font-weight-normal text-success mb-2">
                Your email address
              </h2>
              <p>
                We do not know your e-mail address unless you give it to us. We
                will only record your e-mail address if you send us a message.
                We will use it only for the purpose for which you have provided
                it. We will not disclose it without your consent.
              </p>
              <p>
                If you have given us your e-mail address, but do not want to
                receive e-mail from us in the future, please let us know by
                sending us an e-mail via our contact form.
              </p>
            </article>
            <article className="mb-5">
              <h2 className="h3 font-weight-normal text-success mb-2">
                How we use this information
              </h2>
              <p>
                We use this information to improve the content of our Web site
                and analyse what pages people visit, and (if you subscribe to
                our e-mail newsletters) to keep in touch with you about updates
                to our Web site. If you use our services, we also use your
                information to customise the content of our Web site for you.
              </p>
              <p>
                If you supply us with your e-mail address or postal address when
                requesting a service, we might send you information on new
                products and services or upcoming events. If you do not wish to
                receive such mailings, please let us know by sending us an
                e-mail.
              </p>
              <p>
                If you supply your telephone number when ordering a product or
                service, we will only use it with respect to that order.
              </p>
            </article>
            <article className="mb-5">
              <h2 className="h3 font-weight-normal text-success mb-2">
                Who has access to your information?
              </h2>
              <p>
                We use this information internally and share it with other
                people or organisations who need to know it as part of working
                with us in our normal business activities.
              </p>
              <p>
                We do not share your personal information with others except
                under these conditions, and we do not disclose or sell your
                personal information for use in mailing lists or databases.
              </p>
            </article>
            <article className="mb-5">
              <h2 className="h3 font-weight-normal text-success mb-2">
                Future variations
              </h2>
              <p>
                From time to time, we may use your information for new,
                unanticipated uses not previously disclosed in our privacy
                notice. If our information practices change at some time in the
                future we will post the policy changes to our Web site to notify
                you of these changes and provide you with the ability to opt out
                of these new uses. If you are concerned about how your
                information is used, you should check back at our Web site
                periodically.
              </p>
            </article>
            <article className="mb-5">
              <h2 className="h3 font-weight-normal text-success mb-2">
                Corrections
              </h2>
              <p>
                Upon request, we'll tell you what information we have recorded
                about you. If any of this information is incorrect, or you want
                us to remove all information about you, please send us an e-mail
                using our contact form.
              </p>
              <p>
                If you feel that this site is not following its stated
                information policy, please{" "}
                <Link to={Routes.Contact}>contact us</Link>.
              </p>
            </article>
          </div>
        </div>
      </Section>

      <Section bgColor="violet200">
        <div className="row">
          <div className="col-12 col-lg-8 offset-lg-1">
            <h2 className="font-weight-normal text-primary mb-2">
              More information
            </h2>
            <p className="mb-0">
              If you would like to know more about on-line privacy, visit the{" "}
              <a
                href={Routes.PrivacyCommission}
                target="_blank"
                rel="noopener noreferrer"
              >
                Australian Privacy Commissioner's website
              </a>
              .
            </p>
          </div>
        </div>
      </Section>
    </Layout>
  );
}

export const Head = () => {
  return <Meta title="Privacy Policy" url={Routes.PrivacyPolicy} />;
};
